.appointmentdiv{
    padding-top: 10%;
    padding-bottom: 5%;
    background-image: url("../assets/Backgroundimg2.svg");
    height: auto;
    /* position: absolute; */
    text-align: center;
    align-items: center;
}

.mainText{
    color: #09a880;
    font-weight: 400;
    font-size: 128px;
    font-family: "DM Serif Display", 'Sans-serif';
}

.mainText2{
    color:#09a880;
    font-weight: 400;
    font-size: 120px;
    font-family: "DM Serif Display", 'Sans-serif';
}

.desccription{
    max-width: 100%;
    padding-left: 25%;
    padding-right: 25%;
    color: gray;
    margin-top: 2%;
}



.appointment-button{
    background-color: #f49421;
    padding: 10px;
    width: auto;
    border: #f49421;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 2px rgb(244, 148, 33);
    line-height: 28px;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 2%;
    margin: 2%;
}

.appointment-button:hover{
   
        background-color:#09a880;
        transition: 0.7s;
        border: #09a880;
        box-shadow: 0px 0px 2px 2px rgb(4 167 156);

}

@media (min-width: 300px) and (max-width:1000px) {

    .mainText{
        color: #1c3a6b;
        font-weight: 200;
        font-size: 64px;
        font-family: "DM Serif Display", 'Sans-serif';
    }

    .desccription{
        max-width: 100%;
        padding-left: 0%;
        padding-right: 0%;
        color: gray;
        text-align:center;
    }
    
    .mainText2{
        color: #1c3a6b;
        font-weight: 200;
        font-size: 60px;
        font-family: "DM Serif Display", 'Sans-serif';
    }

}