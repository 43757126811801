@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

header {
  /* background-image:linear-gradient(to right,rgba(4,167,156, 0.5), rgba(247, 147, 33, 1) ); */
  /* background-color: #ffffff; */
  min-height: 3.5rem;
  padding: 0 10vw;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4); */
}
/* .stickeyNav{
  background-color: red;
  position: fixed;
  display: flex;
  width: 100%;
} */

.NavName{
    height: 8rem;
    width: 10rem;
    margin-top: 2rem;
}

.NavLogo{
    height: 3rem;
    width: 3rem;
    margin-top: 2rem;
}


.main-header {
  /* position: fixed; */
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-family: "poppins", sans-serif;
}
.brand-logo {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: inherit;
  text-decoration: none;
  color: #fff;
  font-family: "poppins", cursive, sans-serif;
  font-weight: 200;
}
/* .brand-logo-name {
  font-size: 2rem;
} */
.main-nav ul {
  
  display: flex;
  margin: 0;
  padding: auto;
  list-style: none;
  color: #09a880;
  padding-top: 3%;
}

.main-nav a {
  color: inherit;
  text-decoration: none;
  padding: 0 1rem;
  font-size: large;
  font-weight:300;
}
.main-nav a:hover {
  color: #f49421;
}
.toggle-button {
  display: none;
  margin-top: 4rem;
  margin-right: 1rem;
}
.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: #f49421;
  border-radius: 2px;

}

@media only screen and (max-width: 1000px) {
  header {
    padding: 0 5vw;
  }

  
}
@media only screen and (max-width: 1100px) {
  .toggle-button {
    position: absolute;
    right: 30px;
    color: #f49421;
    /* top: 15px; */
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
    width: 30px;
    cursor: pointer;
    display: flex;
  }

  .main-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .main-nav ul {
    flex-direction: column;
    align-items: flex-start;
    display: none;
  }
  .main-nav li {
    padding: 0.6rem 0;
  }
  .main-nav a {
    padding: 0;
  }
  .main-nav ul.active {
    display: flex;
  }
}

