.patientsHeading {
    color: #09a880;
    /* font-family: 'Exo 2', sans-serif; */
    font-size: 46px;
    font-weight: 900;
    text-transform: uppercase;
  }

.side-Heading{
    text-align: left !important;
    color: #f49421;
    
}

.patient-container{
    padding-left: 10%;
}

.rightList{
    
    text-align: left;
    padding-left: 5%;
    padding-top: 2%;
    color:#09a880
}

.resptList{
    text-align: left;
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 5%;
    color:#09a880
}