.wrappersz{
    width: "100%";
    // justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
  
}

.card1{
    margin:15px;
    // width: 0%;
}

.delete {
    background-color: #f44336; /* Green */
    border-radius: 10px;
    border: none;
    color: white;
    padding: 7px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 35px 1px;
    float: right;
    cursor: pointer;
  }