.mainTextfacilities{
    color: #09a880;
    font-weight: 300;
    font-size: 50px;
    padding-top: 5%;
    font-family: "DM Serif Display", 'Sans-serif';
}

/* .mainText2{
    color:#09a880;
    font-weight: 400;
    font-size: 120px;
    font-family: "DM Serif Display", 'Sans-serif';
} */


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  .grid-item{
      width: 200px;
    }

  .theimg{
    width: 100%;
  }


  @media (max-width: 800px) {
    .my-masonry-grid {
      margin-left: -15px; /* gutter size offset */
    }
    .my-masonry-grid_column {
      padding-left: 15px; /* gutter size offset */
    }
    .my-masonry-grid_column > div {
      margin-bottom: 15px; /* space between items */
    }
  }