#cardhead{
    color: #f49421;
}





a:hover {
    text-decoration: none;
}

.np {
    padding: 0px;
}

.featuredPropBox {}

.featuredPropBox ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: outside none none;
    padding: 0;
}

.featuredPropBox ul li {
    background-color: #eeeeee;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
    height: 250px;
    margin: 5px;
    width: 32%;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
}

.featuredPropBox ul li:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(22, 22, 22, 0.6);
    transition: all 0.3s;
}

.featuredPropBox ul li:nth-child(1) {
    background-image: url('../assets/critical.jpeg');
}

.featuredPropBox ul li:nth-child(2) {
    background-image: url('../assets/dietetics.jpeg');
}

.featuredPropBox ul li:nth-child(3) {
    background-image: url('../assets/emergency.jpeg');
}

.featuredPropBox ul li:nth-child(4) {
    background-image: url('../assets/generalsurgery.jpeg');
}

.featuredPropBox ul li:nth-child(5) {
    background-image: url('../assets/Diabetology.webp');
}
.featuredPropBox ul li:nth-child(6) {
    background-image: url('../assets/gynic.jpeg');
}

.featuredPropBox ul li:nth-child(7) {
    background-image: url('../assets/ortho.jpeg');
}

.featuredPropBox ul li:nth-child(8) {
    background-image: url('../assets/pediatric.jpeg');
}

/* .featuredPropBox ul li:nth-child(9) {
    background-image: url('https://i.ibb.co/RhZgpSG/20845.jpg');
} */

.featuredPropBox ul li .fplogo {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 1;
    transition: all 0.3s;
}

.featuredPropBox ul li .fplogo img {
    width: 100%;
}

.featuredPropBox ul li .fptext {
    display: none;
    font-size: 16px;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease 0s;
    width: 65%;
    z-index: 1;
}

.featuredPropBox ul li .fptext p {
    color: whitesmoke;
    margin: 0px;
    font-size: medium;
    font-weight: 600;
}

.featuredPropBox ul li:hover {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0.2) inset;
}

.featuredPropBox ul li:hover:after {
    background-color: rgba(22, 22, 22, 0.3);
}

.featuredPropBox ul li:hover .fplogo {
    display: none;
}

.featuredPropBox ul li:hover .fptext {
    display: block;
}