.container{
  
  width: 800px;
  height: auto;
  margin: 30px auto;
}

.floating{
  
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10%;
    right: 5%;
    height: 60px;
    width: 200px;
    // border-radius: 50%;
    background-color: #09a880;
    // visibility: ;
   

}

.Uploadg {
  background-color: #09a880; /* Green */
  border-radius: 10px;
  border: none;
  color: white;
  // padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  position: absolute;
  // margin: 70px 2px;
  cursor: pointer;
}

.delete {
  background-color: #f44336; /* Green */
  border-radius: 10px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 70px 2px;
  cursor: pointer;
}




.box{
  margin: 20px;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  margin-right: 1%;
  background: white;
  border-radius: 10px;
  transition: 0.9;
}

.cardsImg{
 height: 226px;
 width: 226px;
}
.box:hover{
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
  cursor: pointer;
}
.adminImgs{
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
h3,p{
  text-align: center;
  color: rgb(104, 92, 92);
}
p{
  font-size: 15px;
  padding: 0 5px;
}
@media (max-width: 800px){
  .container{
      width: 100%;
  }
}
@media (max-width: 600px){
  .container{
      width: 85%;
      display: block;
  }
  .box{
      width: 100%;
      margin-bottom: 4%;
  }
}