/* about-img */
@import url('https://fonts.googleapis.com/css?family=Exo+2:700');


  
  h1 {
    color: #09a880;
    font-family: 'Exo 2', sans-serif;
    font-size: 46px;
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .p0 {
    /* max-width: 60%; */
    min-height: 200px;
    padding: 0;
    color: #f49421;
    /* font-family: OpenSans; */
    font-size:18px;
    font-weight: 600;
    line-height: 30px;
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    display: flex;

  }

  .doc-img{
      display: flex;
      width: 100%;
      height: 40%;
      position: absolute;
      margin-bottom: 40%;
      overflow: hidden;
  }
  
  /* .square {
    height: 60px;
    width: 60px;
    border: 1px dashed white;
    margin: 0 0 0 55px;
   
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    transform: rotateZ(44deg);
  }
  
  .square:hover {
    background-color: rgba(27, 182, 239, 0.2);
    transition: ease 0.2s;
    cursor: pointer;
  }
  
  .square .icons {
    position: absolute;
    transform: rotateZ(-44deg);
    margin: 20px 0 0px 21px;
  }
  
  .fa-facebook,
  .fa-twitter,
  .fa-dribbbler,
  .fa-youtube,
  .fa-dribbble {
    width: 11px;
    height: 22px;
    color: white;
    font-family: FontAwesome;
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .square:hover .fa-facebook {
    color: rgba(59, 89, 152, 1)
  }
  
  .square:hover .fa-twitter {
    color: rgba(27, 182, 239, 1)
  }
  
  .square:hover .fa-dribbble {
    color: rgba(199, 59, 111, 1)
  }
  
  .square:hover .fa-youtube {
    color: rgba(229, 45, 39, 1)
  }
  .logo{
     margin: 1em;
     position: fixed;
     bottom: -72px;
     z-index: 99999999999;
     right: 0;
  } */

  .pen{
    fill:black;
   animation:rotateInDownLeft 3s forwards;
  }
  .all{
    animation:rotateOut 3s forwards;
  }
  @keyframes rotateInDownLeft {
    from {
      transform-origin: left bottom;
      transform: rotate3d(0,0,0, 0deg);
      opacity: 1;
    }
  
    to {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
   /* transform: ; */
   transform:translateX(850px) translateY(-83px) rotate3d(0,0,1, -60deg);
      opacity: 1;
    }
  }
  @keyframes rotateOut {
    from {
      -webkit-transform-origin: center;
      transform-origin: center;
      opacity: 1;
    }
  
    to {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
      transform: rotate3d(0, 0, 1, 90deg);
      opacity: 1;
    }
  }