.Landing-page-background{
    /* background-image:linear-gradient(to right,rgba(4,167,156, 0.5), rgba(247, 147, 33, 1) ); */
        /* background-image: url("../assets/whitebg.png"); */
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        height: 900px;
        /* clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 51% 100%, 0 100%, 0 0); */

}

.maxwidth{
    width: 100%;
   
}

.LandingNurse {
    /* padding: 100px 0px 0px 3%; */
    position:static;
    float: right;
    padding-right:10% ;
    padding-top: 1%;
   
}

.Landing-Headings{
    animation: fadeIn 5s;
    padding-left: 12%;
    /* padding-top: 6%; */
    width: 50%;
}

.vision-mission{
    animation: fadeIn 5s;
    padding-left: 12%;
    /* padding-top: 6%; */
    width: 50%;
}

main{
    color: #FFFFFF;
    font-family:'Courier New', Courier, monospace;
    text-align: center;
    flex-direction: column;
    
}
.MainText{
    color: #09a880;
    font-family: "DM Serif Display", Sans-serif;
    font-size: 65px;
    font-weight: 500;
    line-height: 1.4em;
    text-align: left;
    padding-top: 20%;
}
.typeWriter{
    color: #f49421;
}

.MainCaption2{
    color: #f49421;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    text-align: left;
    opacity: 1;
    transition: opacity 2s ease 0s;
}

cursor{
    color: #f49421;
}



@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


@media (max-width: 800px) and (max-width: 1000px) {
    .LandingNurse {
        display: none;
    }

    .Landing-Headings{
        animation: fadeIn 5s;
        padding-left: 7%;
        padding-top: 10%;
        width: 100%;
    }
    
    .MainText{
        color: #09a880;
        font-family: "DM Serif Display", Sans-serif;
        font-size: 96px;
        font-weight: 500;
        line-height: 1.4em;
        text-align: center;
    }
    
    .MainCaption2{
        color: #f49421;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4em;
        text-align: center;
    }
}

/* @media only screen and (max-width: 800px) {
    .LandingNurse {
        display: none;
    }


    .Landing-page-background{
    
        background-image: url("../assets/LandingBackground.png");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        height: 800px;
    
}
} */


@media only screen and (min-width: 1900px) {

    .LandingNurse {
        /* padding: 100px 0px 0px 3%; */
        position: relative;
        float: right;
        padding-right:10% ;
        padding-top: 0%;
    }
}


@media (min-width: 800px) and (max-width:1180px) {
    .LandingNurse {
        /* padding: 100px 0px 0px 3%; */
        position: relative;
        float: right;
        padding-right:0% ;
        padding-top: 15%;
        height: 40%;
        width: 40%;
    }


    .Landing-Headings{
        padding-left: 3%;
        padding-top: 30%;
        width: 100%;
    }
    
    .MainText{
        color: #09a880;
        font-family: "DM Serif Display", Sans-serif;
        font-size: 46px;
        font-weight: 500;
        line-height: 1.4em;
        text-align: center;
    }
    
    .MainCaption2{
        color: #f49421;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4em;
        text-align: center;
        /* position: absolute; */
        /* width: 10%; */

    }
}



@media (min-width: 300px) and (max-width:800px) {


    .Landing-page-background{
    
        /* background-image: url("../assets/LandingBackground.png"); */
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        height: 600px;
        width: 100%;
    
}
    .LandingNurse {
        display: none;
    }

    .Landing-Headings{
        padding-left: 3%;
        padding-top: 10%;
        width: 100%;
    }
    
    .MainText{
        color: #09a880;
        font-family: "DM Serif Display", Sans-serif;
        font-size: 46px;
        font-weight: 500;
        line-height: 1.4em;
        text-align: center;
    }
    
    .MainCaption2{
        color: #f49421;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4em;
        text-align: center;
    }
}

.text_1 {
    animation: text1;
    color: #f49421;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    text-align: left;
    opacity: 1;
    transition: opacity 2s ease 0s;
  }
  
  .text_2 {
    animation: text2;
    color: #f49421;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    text-align: left;
    opacity: 1;
    transition: opacity 2s ease 0s;
  }
  
  .text_1, .text_2 {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-duration: 20s;
    animation-timing-function: steps(25, end);
    animation-iteration-count: infinite;
  }
  
  .text_1::after, .text_2::after {
    content: "|";
    position: absolute;
    right: 0;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
  }
  
  @keyframes text2 {
    0%, 50%, 100% {
      width: 0;
    }
    
    60%, 90% {
      width: 21.2em;
    }
  }
  
  @keyframes text1 {
    0%, 50%, 100% {
      width: 0;
    }
    10%, 40% {
      width: 17em;
    }
  }
  
  @keyframes caret {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }