@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

/*   color variables */
$clr-primary: #0e48fe;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;

/*   border radius */
$radius: 0.2rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.outsideColor{
  color:#039d69
}

.insideColor{
  color:#f49421
}


#inputs{
  margin: 8px;
  // margin-bottom: ;
  padding: 8px;
  // margin:15px;
}

body {
  font-family: "Noto Sans", sans-serif;
  color: $clr-gray500;
}

.card1__title{
  color:#f49421
}

.wrappers {
  margin: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
}


  .upload {
    background-color: #039d69; /* Green */
    border-radius: 10px;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 70px 2px;
    cursor: pointer;
  }


.card1 {
  box-shadow: 0 0 10px 5px $clr-gray300;
  border-radius: $radius;
  min-width: 28rem;
  // height: 500px;
  display: flex;

  &__img {
    width: 14rem;
    height: 11rem;
    object-fit: cover;
  }

  &__body {
    margin: 1rem;
    flex-grow: 1;

  }
  &__title {
    // line-height: 1.4rem;
    // margin-bottom: 0.5rem;
    float: left;
  }
  &__description {
    // line-height: 1.2rem;
    float: left;
    color: #039d69;
  }
  &__price {
    font-size: 1.4rem;
    margin-top: 0.6rem;

    &::before {
      content: "$";
      font-size: 1rem;
      position: relative;
      top: -0.3rem;
      padding-right: 0.1rem;
    }
  }

  &__btn {
    border: none;
    color: whitesmoke;
    border-top: 1px solid $clr-gray300;
    background-color: #f44336;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    width: 60%;
    height: 20%;
    // padding-top: 1rem;
    // margin-top: 1rem;
    cursor: pointer;

    &:hover {
      color: $clr-primary;
    }
  }
}

@media screen and (min-width: 600px) {
  .wrappers {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card1 {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;

    &__img {
      width: 100%;
      height: 12rem;
    }
  }
}