.HeadingSection{
    /* background-color: aliceblue; */
    align-items: center;
    /* color: whitesmoke; */
    text-align: center;
    align-self: center;
    padding-top: 50px;
}

.Section-Main-Head{
    font-size:xx-large;
    color: #09a880;
    

}

.Section-sub-Head{
    font-size: medium;
    color: #f49421;
    
}




/* ***************************CARDS*********************** */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}
.cardbody {
	display: flex;
	justify-content: center;
	align-items: center;
	/* min-height: 100vh; */
	background: white;
}

.container2 {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	flex-wrap: wrap;
	z-index: 1;
  
    /* background-color: #2aa7ff; */
}
.container2 .card {
    /* rgba(170 187 204, 0.5) */
	position: relative;
	width: 280px;
	height: 300px;
	margin: 5px;
	/* box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.479); */
	/* border-radius: 15px; */
	background: rgba(245,255,255, 0.1);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	/* border-top: 1px solid rgba(170 187 204, 0.5); */
	/* border-left: 1px solid rgba(170 187 204, 0.5); */
	backdrop-filter: blur(5px);
}

.container2 .card .content {
	padding: 20px;
	text-align: center;
	transform: translateY(50px);
	transition: 0.5s;
	
}

.container2 .card :hover {
	transform: translateY(0px);
	transition: 0.5s;
}

.container2 .card :hover {
	transform: translateY(0px);
	transition: 0.5s;
}

.container2 .card:hover .invisible-button{
	display:inline-block;
    background-color: #f49421;
    padding: 10px;
    width: 50%;
    border: #f49421;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 2px rgb(244, 148, 33);
    /* line-height: 28px; */
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 2%;
    margin: 2%;
}


.container2 .card .content p :hover{
	color: #f49421;
}


.container2 .card .content h2 {
	position: absolute;
	top: -40px;
	right: 30px;
	font-size: 8em;
	color: rgba(170, 187, 204, 0.2);
	pointer-events: none;
}
.container2 .card .content h3 {
	font-size: 1.4em;
	color:#09a880;
	pointer-events: none;
	z-index: 1;
}

.container2 .card .content p {
	font-size: 1em;
	color: #1C3A6B;
	font-weight: 300;
}
.container2 .card .content a {
	position: relative;
	display: inline-block;
	padding: 8px 20px;
	margin-top: 20px;
	/* background: green; */
	/* color:#000; */
	/* border-radius: 20px; */
	text-decoration: none;
	font-weight: 500;
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); */
}

.invisible-button{
	visibility: none;
	color: white;
}


